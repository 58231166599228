<template>
  <div>
    <v-card class="rounded-lg" elevation="0">
      <section class="header-table">
        <v-text-field
          v-model="search1"
          @keyup.enter="searchRegister"
          class="rounded-lg"
          append-icon="mdi-magnify"
          label="Instituição..."
          single-line
          hide-details
          outlined
          clearable
          dense
        ></v-text-field>
        <div class="space"></div>
        <v-select
          dense
          outlined
          clearable
          v-model="regional_nucleus_health_id"
          :items="regionalNucleusHealthList"
          @keyup.enter="searchRegister"
          item-text="name"
          item-value="id"
          label="Macrorregião"
        ></v-select>
        <div class="space"></div>
        <v-select
          dense
          outlined
          v-model="county"
          :items="countyList"
          @keyup.enter="searchRegister"
          clearable
          item-text="name"
          item-value="id"
          label="Município"
        ></v-select>
        <div class="space"></div>
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="success"
          @click="searchRegister"
        >
          <v-icon small class="mr-2" color="#FFFFFF"> mdi-magnify </v-icon>
          <span class="textBtn"> Pesquisar </span>
        </v-btn>

        <div class="space"></div>
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="primary"
          @click="dialog = true"
        >
          <v-icon small class="mr-2" color="#FFFFFF"> mdi-plus </v-icon>
          <span class="textBtn"> Novo </span>
        </v-btn>
        <div class="space"></div>

        <v-btn
          class="rounded-lg"
          elevation="0"
          color="#C62828"
          @click="clearSearch"
        >
          <span class="textBtn"> LIMPAR</span>
        </v-btn>
      </section>

      <v-data-table
        :headers="headers"
        :items="instituteList"
        :footer-props="{
          'items-per-page-text': 'Registros por Página',
        }"
        :page="page"
        :pageCount="numberOfPages"
        :server-items-length="TotalResgister"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :loading="loading"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <Crud :resource="item" v-on:eventname="crudUser" />
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" width="600px" persistent>
        <v-card class="create-user-dialog rounded-lg">
          <div class="banner">
            <h4>Criar Instituição</h4>
          </div>

          <AlertError
            class="mb-5"
            v-model="displayError"
            :resource="displayError"
          />

          <v-form @submit.prevent="create" ref="form">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  class="rounded-lg"
                  label="Nome da Instituição"
                  placeholder="Instituição"
                  outlined
                  v-model="name"
                  :rules="nameRules"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  class="rounded-lg"
                  label="Responsável"
                  placeholder="Responsável"
                  outlined
                  v-model="responsible"
                  :rules="nameRules"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="email"
                  class="rounded-lg"
                  label="Email"
                  placeholder="Email"
                  outlined
                  v-model="email"
                  :rules="nameRules"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  class="rounded-lg"
                  label="CNPJ"
                  placeholder="CNPJ"
                  outlined
                  v-model="cnpj"
                  v-mask="'##.###.###/####-##'"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  class="rounded-lg"
                  label="Telefone"
                  placeholder="Telefone"
                  v-mask="'(##) #####-####'"
                  outlined
                  v-model="phone"
                  :rules="nameRules"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  class="rounded-lg"
                  label="Logradouro"
                  placeholder="Logradouro"
                  outlined
                  v-model="address.public_place"
                  :rules="nameRules"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  class="rounded-lg"
                  label="Bairro"
                  placeholder="Bairro"
                  outlined
                  v-model="address.district"
                  :rules="nameRules"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  class="rounded-lg"
                  label="CEP"
                  placeholder="CEP"
                  v-mask="'#####-###'"
                  outlined
                  v-model="address.cep"
                  :rules="nameRules"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  min="0"
                  class="rounded-lg"
                  label="Número"
                  placeholder="Número"
                  outlined                                                      
                  v-model="address.address_number"
                  v-mask="'####'"
                  :rules="numberResidencie"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  dense
                  outlined
                  v-model="address.country_id"
                  :items="countryList"
                  :rules="nameRules"
                  item-text="name"
                  item-value="id"
                  label="País"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  dense
                  outlined
                  v-model="address.state_id"
                  :items="stateList"
                  :rules="nameRules"
                  item-text="name"
                  item-value="id"
                  label="Estado"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  dense
                  outlined
                  v-model="address.county_id"
                  :items="countyList"
                  :rules="nameRules"
                  item-text="name"
                  item-value="id"
                  label="Cidade"
                ></v-select>
              </v-col>
            </v-row>

            <v-card-actions class="cardFooter">
              <v-spacer></v-spacer>

              <v-btn
                class="rounded-lg"
                color="#e74c3c"
                text
                elevation="0"
                @click="dialog = false"
              >
                <span>Cancelar</span>
              </v-btn>

              <v-btn
                class="rounded-lg"
                elevation="0"
                color="#27ae60"
                type="submit"
              >
                <span class="textBtn">Enviar dados</span>
                <v-icon color="#FFFFFF" small class="ml-2"
                  >mdi-arrow-right</v-icon
                >
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar v-model="snackbar">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import Crud from "./CrudInstitute-comp.vue";
import AlertError from "../alerts/Error-component.vue";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      search1: "",
      search2: "",
      search3: "",
      headers: [
        { text: "Ações", value: "actions", sortable: false, width: "20%" },
        {
          text: "Instituição",
          align: "start",
          value: "name",
          sortable: false,
        },
        {
          text: "CNPJ",
          align: "start",
          value: "cnpj",
          sortable: false,
        },
        {
          text: "Email",
          align: "start",
          value: "email",
          sortable: false,
        },
        {
          text: "Responsável",
          align: "start",
          value: "responsible",
          sortable: false,
        },
        {
          text: "Telefone",
          align: "start",
          value: "phone",
          sortable: false,
        },
        {
          text: "Cep",
          align: "start",
          value: "address[0].cep",
          sortable: false,
        },
        {
          text: "Logradouro",
          align: "start",
          value: "address[0].public_place",
          sortable: false,
        },
        {
          text: "Bairro",
          align: "start",
          value: "address[0].district",
          sortable: false,
        },
        {
          text: "Cidade",
          align: "start",
          value: "address[0].county.name",
          sortable: false,
        },
        {
          text: "Estado",
          align: "start",
          value:
            "address[0].county.health_region.regional_nucleus_heath.state.name",
          sortable: false,
        },
      ],

      nameRules: [(v) => !!v || "O campo é obrigatório"],
      numberResidencie: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 4) || "Você excedeu o tamanho máximo!",
        (v) => (v && v > 0) || "O número é abaixo do esperado",
      ],

      displayError: {
        display: false,
        message: [],
      },

      page: 1,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      options: {},
      sortBy: "name",

      TotalResgister: 0,

      message: "",
      snackbar: false,
      loadingBTN: false,
      exibirMensagem: false,
      messageError: "",
      foundation: "",
      name: "",
      responsible: "",
      email: "",
      cnpj: "",
      phone: "",
      county: "",
      address: {},

      instituteList: [],
      countryList: [],
      stateList: [],
      countyList: [],
      HealthRegion: "",
      regional_nucleus_health_id: "",
      HealthRegionList: [],
      regionalNucleusHealthList: [],
    };
  },

  components: {
    Crud,
    AlertError,
  },

  watch: {
    sortBy: {
      handler() {
        console.log(this.sortBy);
      },
    },
    options: {
      handler() {
        this.indoDataTable();
      },
    },
    deep: true,
  },

  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    loadSelects() {
      axios.get(`/county`).then((response) => {
        this.countyList = response.data;
      });
      axios.get(`/country`).then((response) => {
        this.countryList = response.data;
      });
      axios.get(`/state`).then((response) => {
        this.stateList = response.data;
      });

      axios.get(`/health-region`).then((response) => {
        this.HealthRegionList = response.data;
      });

      axios.get(`/regional-nucleus-health/`).then((response) => {
        this.regionalNucleusHealthList = response.data;
      });
    },

    searchRegister() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;

      axios
        .get(`/institute/filter`, {
          params: {
            page: pageNumber,
            per_page: data.per_page,
            name: this.search1,
            regional_nucleus_health_id: this.regional_nucleus_health_id,
            county_id: this.county,
          },
        })
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
          this.TotalResgister = response.data.total;
          this.numberOfPages = response.data.last_page;
        });
    },

    indoDataTable() {
      this.loading = true;
      const { page } = this.options;
      let pageNumber = page;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;

      axios
        .get(`/institute/filter`, {
          params: {
            page: pageNumber,
            per_page: data.per_page,
            name: this.search1,
            regional_nucleus_health_id: this.regional_nucleus_health_id,
            county_id: this.county,
          },
        })

        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
          this.TotalResgister = response.data.total;
          this.numberOfPages = response.data.last_page;
        });
    },

    create() {
      if (this.$refs.form.validate() == true) {
        const dados = new Object();
        dados.name = this.name;
        dados.responsible = this.responsible;
        dados.email = this.email;
        dados.cnpj = this.cnpj;
        dados.phone = this.phone;
        dados.address = this.address;

        axios
          .post(`/institute`, dados)
          .then((response) => {
            this.indoDataTable();
            this.resetForm();
            this.message = response.data.message;
            this.snackbar = true;
            this.dialog = false;
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
      }
    },

    crudUser(resource) {
      if (resource != "") {
        axios.delete(`/institute/${resource}`).then((response) => {
          this.indoDataTable();
          this.message = response.data.message;
          this.snackbar = true;
        });
        return;
      }

      this.indoDataTable();
    },

    clearSearch() {
      this.search1 = "";
      this.HealthRegion = "";
      this.regional_nucleus_health_id = "";
      this.county = "";
      this.indoDataTable();
    },
  },

  mounted() {
    this.indoDataTable();
    this.loadSelects();
  },
};
</script>

<style scoped>
.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #27ae60, #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #27ae60, #1abc9c);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
