import { render, staticRenderFns } from "./CrudInstitute-comp.vue?vue&type=template&id=7b266e56&scoped=true&"
import script from "./CrudInstitute-comp.vue?vue&type=script&lang=js&"
export * from "./CrudInstitute-comp.vue?vue&type=script&lang=js&"
import style0 from "./CrudInstitute-comp.vue?vue&type=style&index=0&id=7b266e56&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b266e56",
  null
  
)

export default component.exports